@tailwind base;
@tailwind components;
@tailwind utilities;

.pb-6 {
	direction:rtl;
}

div {
  font-family: 'BCSans', 'Noto Sans', 'Segoe UI', sans-serif;
}

.cell-animation {
  animation: revealCharCell linear;
  animation-duration: 0.15s;
}

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

#keyboard-cont {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
}

#keyboard-cont div {
  display: flex;
}

.first-row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.second-row {
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.third-row {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.fourth-row {
  display: flex;
  justify-content: center;
  width: 100%;
}

.keyboard-button {
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem;
  margin: 0 2px;
  cursor: pointer;
  text-transform: uppercase;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border: none;
  border-radius: 3px;
  background-color: #818384;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9%;
}

.keyboard-special {
    background-color: #538d4e
}